export default function Button({
    className = "",
    disabled,
    children,
    ...props
}) {
    return (
        <button
            {...props}
            className={
                `bg-black border-primary p-4 px-8 text-primary border-2 font-body rounded-md font-lg uppercase font-semibold w-full md:w-auto lg:w-auto ${
                    disabled && "opacity-25"
                } ` + className
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
}
