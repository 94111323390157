export default function Heading({ children, className }) {
    return (
        <h2
            className={`text-primary font-heading text-4xl md:text-5xl lg:text-5xl font-black w-auto ${className}`}
        >
            {children}
            {/* <div className="bg-primary border-b-4 border-primary w-28"></div> */}
        </h2>
    );
}
