import Heading from "./Heading";
import Text from "./Text";

export default function SectionLabel({
  title,
  description,
}) {
  return (
    <section className="w-full">
      <div className="px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 flex flex-col justify-center items-center">
        <div className="text-center">
          <Heading className="text-center mb-6">{title}</Heading>
          <Text>{description}</Text>
        </div>
      </div>
    </section>
  );
}
