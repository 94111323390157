import { useEffect, useMemo, useRef, useState } from "react";

const useElementOnScreen = (options) => {
    const containerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const makeAppear = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) setIsVisible(true);
    };

    const makeAppearRepeating = (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
    };

    const callBack = options.reappear ? makeAppearRepeating : makeAppear;

    useEffect(() => {
        const containerRefCurrent = containerRef.current;
        const observer = new IntersectionObserver(callBack, options);
        if (containerRefCurrent) observer.observe(containerRefCurrent);

        return () => {
            if (containerRefCurrent) {
                observer.unobserve(containerRefCurrent);
            }
        };
    }, [containerRef, options, callBack]);

    return [containerRef, isVisible];
};

const AnimateOnScroll = ({ delay, children, reappear, threshold = 0.5 }) => {
    const [containerRef, isVisible] = useElementOnScreen({
        threshold: threshold,
        reappear: reappear,
    });

    const getDelay = useMemo(() => {
        switch (delay) {
            case 0:
                return "delay-0";
            case 75:
                return "delay-75";
            case 100:
                return "delay-100";
            case 150:
                return "delay-150";
            case 200:
                return "delay-200";
            case 300:
                return "delay-300";
            case 500:
                return "delay-500";
            case 700:
                return "delay-700";
            case 1000:
                return "delay-1000";
            default:
                return "delay-0";
        }
    }, [delay]);

    return (
        <>
            <div
                // @ts-ignore
                ref={containerRef}
                className={`w-full transition duration-0 md:duration-500 lg:duration-500 delay-0 md:${getDelay} lg:${getDelay} ${
                    isVisible
                        ? "opacity-100 blur-none translate-y-0"
                        : "opacity-100 md:opacity-0 lg:opacity-0 blur-none md:blur-md lg:blur-md translate-y-0 md:translate-y-20 lg:translate-y-20"
                }  motion-reduce:transition-none motion-reduce:hover:transform-none`}
            >
                {children}
            </div>
        </>
    );
};

export default AnimateOnScroll;
